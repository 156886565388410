import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { useAuth } from "../context/AuthProvider";
import Login from "../views/Login";
import Register from "../views/Register";
import ForgotPass from "../views/ForgotPass";
import ResetPassword from "../views/ResetPassword";
import Verify from "../views/Verify";
import Settings from "../views/Settings";
import Dashboard from "../views/Dashboard";
import Home from "../views/Home";
import Marketplace from "../views/Marketplace";

const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/",
      element: <Home/>,
    },
    {
      path: "/home",
      element: <Home/>,
    },
    {
      path: "*",
      element: <Login/>,
    },
    {
      path: "/register",
      element: <Register/>,
    },
    {
      path: "/login",
      element: <Login/>,
    },
    {
      path: "/forgot-password",
      element: <ForgotPass/>,
    },
    {
      path: "/reset-password",
      element: <ResetPassword/>,
    },
    {
      path: "/verify",
      element: <Verify/>,
    },
    {
      path: "/marketplace",
      element: <Marketplace/>,
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard/>,
        },
        {
          path: "/task",
          element: "<div></div>", //to be updated
        },
        {
          path: "/mentors",
          element: "<div></div>", //to be updated
        },
        {
          path: "/messages",
          element: "<div></div>", //to be updated
        },
        {
          path: "/settings",
          element: <Settings/>,
        },
        {
          path: "/marketplace",
          element: <Marketplace/>,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/home",
      element: <Home/>,
    },
    {
      path: "/login",
      element: <Login/>,
    },
    {
      path: "/forgot-password",
      element: <ForgotPass/>,
    },
    {
      path: "/register",
      element: <Register/>,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;