import React from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";

const Join = () => {
  return (
    <Box
      sx={{
        borderRadius: 6,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        padding: 8,
        bgcolor: "#fff",
        margin: "2em auto",
        maxWidth: "80%",
        width: "100%",
        mb: 4,
        pl: 8,
      }}
    >
      <Grid container spacing={3} alignItems="center">
        {/* Image Placeholder */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              width: "100%",
              height: "250px",
              bgcolor: "lightgreen",
              borderRadius: "12px",
            }}
          />
        </Grid>

        {/* Text Section */}
        <Grid item xs={12} sm={8}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", color: "#6835f3" }}
          >
            Join Our Community
          </Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ margin: "1em 0" }}
          >
            Lorem ipsum dolor sit amet consectetur. Iaculis vestibulum purus
            facilisi ultrices sed faucibus proin cum ut. Lorem ipsum dolor sit
            amet consectetur. Iaculis vestibulum purus facilisi ultrices sed
            faucibus proin cum ut.
          </Typography>

          {/* Email Input and Button */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "1em",
            }}
          >
            <TextField
              placeholder="Enter your email address"
              variant="outlined"
              sx={{
                flexGrow: 1,
                bgcolor: "#f9f9f9",
                borderRadius: "6px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                },
              }}
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6835f3",
                color: "#fff",
                borderRadius: "8px",
                padding: "12px 24px",
                textTransform: "none",
                whiteSpace: "nowrap",
                "&:hover": {
                  backgroundColor: "#5a28c5",
                },
              }}
            >
              Get Email
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Join;
