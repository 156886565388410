import React from "react";
import { Box, Typography, Divider } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Divider sx={{ mx: "50px" }} />

      <Box
        sx={{
          marginTop: 3,
          padding: 2,
          textAlign: "center",
          color: "grey",
        }}
      >
        <Typography variant="body2" component="p">
          &copy; {new Date().getFullYear()} SOLULU
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
