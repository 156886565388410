import React from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Badge,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";

const Header = () => {
  return (
    <header>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1em",
          px: { xs: "1em", sm: "2em" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flex: 2 }}>
          {/* <Link to="/home" sx={{ textDecoration: 'none', color: 'inherit' }}> */}
          <Typography
            variant="h6"
            component={Link}
            to="/home"
            sx={{ textDecoration: "none", color: "inherit" }}
          >
            SOLULU
          </Typography>
          {/* </Link> */}
          <TextField
            placeholder="search"
            sx={{ marginLeft: "10px", maxWidth: { xs: "200px", sm: "400px" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { height: "35px", padding: "0" },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row" },
            justifyContent: { xs: "space-between", sm: "flex-end" },
            alignItems: "center",
            flex: 1,
            textAlign: { xs: "center", sm: "right" },
            marginTop: { xs: "1em", sm: "0" },
          }}
        >
          <Link to="/home">
            <Button sx={{ color: "black" }}>Home</Button>
          </Link>
          <Button sx={{ color: "black" }}>Browse</Button>
          <Button sx={{ color: "black" }}>Create</Button>
          <IconButton color="inherit">
            <Badge color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Button
            sx={{
              color: "white",
              backgroundColor: "black",
              borderRadius: "20px",
              padding: "6px 12px",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            Account
          </Button>
        </Box>
      </Box>
    </header>
  );
};

export default Header;
