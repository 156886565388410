import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  CardActions,
  Button,
  Divider,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SearchIcon from "@mui/icons-material/Search";

const Featured = () => {
  const aiTitles = ["Marketing", "Accounting", "Finance", "UI/UX", "Idk AI"];

  return (
    <Box
      sx={{
        alignItems: "center",
        padding: "1em",
        px: "4em",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Featured AIs
      </Typography>
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        {aiTitles.map((title, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
            <Card
              sx={{
                position: "relative",
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Image Section */}
              <Box
                sx={{
                  position: "relative",
                  bgcolor: "#C8FAD2",
                  height: "150px",
                  margin: "0.5em",
                  borderRadius: "12px",
                }}
              >
                {/* Heart Icon */}
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    bgcolor: "white",
                    borderRadius: "50%",
                  }}
                >
                  <FavoriteBorderIcon color="primary" fontSize="xs" />
                </IconButton>

                {/* Timer Icon */}
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    bgcolor: "white",
                    borderRadius: "12px",
                    padding: "4px 8px",
                  }}
                >
                  <Typography variant="caption" color="primary">
                    <SearchIcon sx={{ fontSize: "12px" }} /> 2h:24m:04s
                  </Typography>
                </Box>
              </Box>

              {/* Card Content */}
              <CardContent>
                {/* Title Section */}
                <Typography variant="h6" sx={{ color: "#6A33D6" }}>
                  {title}
                </Typography>

                {/* Icons and Tags */}
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "30px",
                        height: "30px",
                        bgcolor: "#85D19D",
                        borderRadius: "50%",
                        marginRight: "-4px",
                        zIndex: 1,
                      }}
                    />
                    <Box
                      sx={{
                        width: "30px",
                        height: "30px",
                        bgcolor: "#85D19D",
                        borderRadius: "50%",
                      }}
                    />
                  </Box>

                  <Typography variant="body2" color="textSecondary">
                    @Robotica, @MysticInk
                  </Typography>
                </Box>
              </CardContent>

              <Divider sx={{ mx: "10px" }} />

              {/* Card Actions */}
              <CardActions
                sx={{
                  justifyContent: "space-between",
                  paddingRight: "10px",
                  display: "flex",
                }}
              >
                <Box sx={{ justifyContent: "flex-start" }}>
                  <Typography sx={{ fontSize: "10px" }} color="textSecondary">
                    Price
                  </Typography>
                  <Typography sx={{ fontSize: "10px" }}>$45 USD</Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                    bgcolor: "#6A33D6",
                    color: "white",
                    "&:hover": {
                      bgcolor: "#5A29BB",
                    },
                  }}
                >
                  Enquire
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Featured;
