import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";

const Jobs = ({ jobs }) => {
  return (
    <Box
      sx={{
        padding: "1em",
        px: "4em",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Jobs
      </Typography>

      <Grid container spacing={2} justifyContent="space-between">
        {jobs.map((job, index) => (
          <Grid item xs={12} sm={6} md={4} lg={1.7} key={index}>
            <Box sx={{ position: "relative" }}>
              {/* Circular Number */}
              <Box
                sx={{
                  position: "absolute",
                  top: "-16px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "32px",
                  height: "32px",
                  bgcolor: "rgba(175, 139, 233, 0.9)",
                  color: "white",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "14px",
                  zIndex: 3,
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                {index + 1}
              </Box>

              {/* Card */}
              <Card
                sx={{
                  textAlign: "center",
                  borderRadius: 6,
                  border: "1px solid #e0e0e0",
                  paddingTop: 1,
                  width: "100%",
                  maxWidth: "250px",
                  margin: "0 auto",
                }}
              >
                {/* Circle Placeholder */}
                <Box
                  sx={{
                    width: "50px",
                    height: "50px",
                    bgcolor: "lightgreen",
                    borderRadius: "50%",
                    margin: "auto",
                    marginTop: "15px",
                  }}
                />

                <CardContent>
                  {/* Job Title */}
                  <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
                    {job.title}
                  </Typography>

                  {/* User Handle */}
                  <Typography color="textSecondary" sx={{ fontSize: "10px" }}>
                    {job.user}
                  </Typography>

                  {/* Items Count */}
                  <Typography sx={{ color: "purple", fontSize: "10px" }}>
                    {job.items} items
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Jobs;
