import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Chip,
  IconButton,
  Divider,
  CardActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const categories = [
  "All categories",
  "Art",
  "Photography",
  "Virtual worlds",
  "Game",
];

const jobs = [
  {
    title: "Iron Man",
    user: "@CosmicArtisan , @Sculptor",
    price: "1.35 ETH",
    time: "2h:24m:04s",
  },
  {
    title: "Feline Brushstrokes",
    user: "@Robotica , @MysticInk",
    price: "1.86 ETH",
    time: "2h:24m:04s",
  },
  {
    title: "Illuminated Enigma",
    user: "@EtherFlow , @NanoNebula",
    price: "2.05 ETH",
    time: "2h:24m:04s",
  },
  {
    title: "Pint-Sized Voyager",
    user: "@BinaryBard",
    price: "1.87 ETH",
    time: "2h:24m:04s",
  },
  {
    title: "Charmingly Fluffed",
    user: "@LunarArt , @Robotica",
    price: "2.32 ETH",
    time: "2h:24m:04s",
  },
  {
    title: "Rosa Vista Realm",
    user: "@CosmicArtisan , @Sculptor",
    price: "1.35 ETH",
    time: "2h:24m:04s",
  },
  {
    title: "Gilded Ebony",
    user: "@Robotica , @MysticInk",
    price: "1.86 ETH",
    time: "2h:24m:04s",
  },
  {
    title: "Hazy Hue Wolf",
    user: "@EtherFlow , @NanoNebula",
    price: "2.05 ETH",
    time: "2h:24m:04s",
  },
  {
    title: "Poker",
    user: "@LunarArt , @Robotica",
    price: "2.32 ETH",
    time: "2h:24m:04s",
  },
  {
    title: "Poker",
    user: "@LunarArt , @Robotica",
    price: "2.32 ETH",
    time: "2h:24m:04s",
  },
];

const JobCard = ({ job }) => (
  <Card
    sx={{
      position: "relative",
      borderRadius: "12px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    }}
  >
    <Box
      sx={{
        position: "relative",
        bgcolor: "#C8FAD2",
        height: "150px",
        margin: "0.5em",
        borderRadius: "12px",
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: "10px",
          left: "10px",
          bgcolor: "white",
          borderRadius: "50%",
        }}
      >
        <FavoriteBorderIcon color="primary" fontSize="xs" />
      </IconButton>

      <Box
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          bgcolor: "white",
          borderRadius: "12px",
          padding: "4px 8px",
        }}
      >
        <Typography variant="caption" color="primary">
          <WatchLaterIcon sx={{ fontSize: "12px" }} /> {job.time}
        </Typography>
      </Box>
    </Box>
    <CardContent>
      <Typography variant="h6" sx={{ color: "#6A33D6" }}>
        {job.title}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
          <Box
            sx={{
              width: "30px",
              height: "30px",
              bgcolor: "#85D19D",
              borderRadius: "50%",
              marginRight: "-4px",
              zIndex: 1,
            }}
          />
          <Box
            sx={{
              width: "30px",
              height: "30px",
              bgcolor: "#85D19D",
              borderRadius: "50%",
            }}
          />
        </Box>

        <Typography variant="body2" color="textSecondary">
          {job.user}
        </Typography>
      </Box>
    </CardContent>

    <Divider sx={{ mx: "10px" }} />

    <CardActions
      sx={{
        justifyContent: "space-between",
        paddingRight: "10px",
        display: "flex",
      }}
    >
      <Box sx={{ justifyContent: "flex-start" }}>
        <Typography sx={{ fontSize: "10px" }} color="textSecondary">
          {job.price}
        </Typography>
        <Typography sx={{ fontSize: "10px" }}>$45 USD</Typography>
      </Box>
      <Button
        variant="contained"
        sx={{
          borderRadius: "20px",
          textTransform: "none",
          bgcolor: "#6A33D6",
          color: "white",
          "&:hover": {
            bgcolor: "#5A29BB",
          },
        }}
      >
        Enquire
      </Button>
    </CardActions>
  </Card>
);

const Explore = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        padding: { xs: "1em", sm: "2em", md: "4em" },
      }}
    >
      {/* Title */}
      <Typography variant="h5" gutterBottom>
        Explore
      </Typography>

      {/* Subtitle */}
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Lorem ipsum dolor sit amet consectetur. Iaculis vestibulum purus
        facilisi ultrices sed faucibus proin cum ut.
      </Typography>

      {/* Filter Categories */}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: { xs: 2, md: 3 } }}>
        {categories.map((category, index) => (
          <Chip
            key={index}
            label={category}
            sx={{
              backgroundColor: index === 0 ? "#d9d1fd" : "#fff",
              border: "1px solid #d9d1fd",
              color: index === 0 ? "#6835f3" : "#000",
              "&:hover": { backgroundColor: "#eceaff" },
            }}
          />
        ))}
        <IconButton
          sx={{
            marginLeft: "auto",
            backgroundColor: "#eceaff",
            borderRadius: 2,
            color: "#6835f3",
          }}
        >
          <FilterAltIcon />
        </IconButton>
      </Box>

      {/* Cards */}
      <Grid container spacing={2}>
        {jobs.map((job, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
            <JobCard job={job} />
          </Grid>
        ))}
      </Grid>

      {/* Load More Button */}
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Button
          variant="outlined"
          endIcon={<ExpandMoreIcon />}
          sx={{
            color: "#6835f3",
            borderColor: "#6835f3",
            borderRadius: "20px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#f0f0ff",
              borderColor: "#6835f3",
            },
          }}
        >
          Load more
        </Button>
      </Box>
    </Box>
  );
};

export default Explore;
