import React from "react";
import {
  Typography,
  Button,
  Box,
} from "@mui/material";

import Header from "./Header";
import Jobs from "./Jobs";
import Featured from "./Featured";
import Explore from "./Explore";
import Join from "./Join";
import Footer from "./Footer";

const Marketplace = () => {
  const jobs = [
    { title: "Extract Load", user: "@Sculptor", items: 890 },
    { title: "Automate", user: "@CosmicArtist", items: 1004 },
    { title: "Cleanse", user: "@NanoNebula", items: 990 },
    { title: "Transform", user: "@BinaryBard", items: 1025 },
    { title: "Precision", user: "@PixelSmithNFT", items: 1145 },
    { title: "Edit", user: "@WavezNFT", items: 840 },
    { title: "Video Creation", user: "@Ethereasel", items: 798 },
  ];
  return (
    <div>
      <Header />
      {/* Hero Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "#E3E3E3",
            p: "6rem",
            textAlign: "left",
            borderRadius: 6,
            mb: 4,
            pl: 6,
            maxWidth: "80%",
            width: "100%",
          }}
        >
          <Typography variant="h3">Explore Artificial Intelligence</Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 4, maxWidth: "40%" }}>
            Lorem ipsum dolor sit amet consectetur. Iaculis vestibulum purus
            facilisi ultrices sed faucibus proin cum ut. Eu dictum nisi
            facilisis non habitant senectus eget sollicitudin.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: 10, bgcolor: "black" }}
          >
            Discover more
          </Button>
        </Box>
      </Box>

      <Featured />
      <Jobs jobs={jobs} />
      <Explore />
      <Join />
      <Footer />
    </div>
  );
};

export default Marketplace;
